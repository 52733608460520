.prediction-tab {
  height: 100%;
  width: 100%;
}

.prediction-left-col {
  height: 100%;
  width: 52%;
}

.prediction-right-col {
  height: 100%;
  width: 47.5%;
}

.selected-row {
  background-color: rgb(144,214,212);
}

.selected-row > .ant-table-column-sort {
  background-color: rgb(144,214,212);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgb(63, 191, 189, 0.4) !important;
}