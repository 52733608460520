.prediction-summary-label {
  text-align: center;
  font-size: 2vh;
}

.activity-calendar {
  text-align: center;
}

.overview-page-header {
  height: 15%;
  width: 100%;
}

.overview-page-header-title {
  margin: 0;
}

.overview-content {
  height: 85%;
  width: 100%;
}

.overview-left-col {
  width: 50%;
  margin-right: 0.5%;
}

.overview-total-predictions {
  height: 20%;
  margin-bottom: 0.5%;
}

.overview-right-col {
  width: 49.5%;
  height: 100%;
}

.overview-prediction-stats {
  height: 79.5%;
}
