.mol-img-container {
  margin-right: 5%;
  margin-left: 5%;
  float: left;
  height: 25%;
  width: 25%
}

.mol-properties {
  width: 60%;
  height: 100%;
  float: left;
  overflow-y: auto;
  padding-left: 1%;
}

.mol-info-card {
  height: 100%;
  width: 100%;
}
