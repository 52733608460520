.auth-component {
  margin-left: 20%;
  min-width: 500px;
}

.home-description {
  position: absolute;
  left: 55%;
  width: 40%;
  top: 1%;
}

.welcome-logo {
  display: block;
  margin: auto;
}

.about-text {
  margin-top: 2vh;
  width: 45vw;
}