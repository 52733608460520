.home-page {
  height: 100vh;
  background-image: url(../../images/background/home-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.company-intro {
  position: relative;
  left: 10%;
  top: 40%; 
}

.home-content {
  justify-content: center;
  /* margin-right: 3vw; */
  overflow: auto;
  height: 85vh;
  min-width: 550px;
}

.home-nav-bar {
  margin-top: 4vh;
  margin-left: 12vw;
  margin-bottom: 4vh;
  /* margin-left: 20vw;
  margin-right: 20vw; */
}

.home-nav-bar-buttons {
  border-radius: 25px;
}


