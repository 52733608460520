.input-tab {
  height: 100%;
  width: 100%;
}

.editor-card {
  float: left;
  width: 50%;
  height: 100%;
}

.input-table-card {
  float: left;
  width: 49.5%;
  height: 100%;
}

.input-help {
  margin-bottom: 10px;
}

.input-methods {
  overflow: auto;
  margin-top: 2vh;
  white-space: nowrap;
  width: 80vw;
  height: 72vh;
}
