::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fff; 
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

::-webkit-scrollbar-thumb:active{
  background: rgb(75, 73, 73)
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

body {
  background: #FAFAFA;
  height: 100%;
  width: 100%;
}