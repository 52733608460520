.forgot-password {
  width: 25%;
  margin: auto;
  margin-top: 10%;
}

.forgot-password-background {
  background-image: url('../../images/background/forgotpass-background.svg');
  background-repeat: no-repeat;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
}