.anticon.menu-logo {
  margin-top: 20%; 
  margin-bottom: 15%;
}

.nav-title {
  margin-left: 8%;
  font-size: 14px;
  font-weight: bold;
}

.app-menu {
  margin-left: 5%;
}

.ant-menu-item-selected {
  background-color: #253c53 !important;
}

.ant-menu-item:hover {
  background-color: #253c53 !important;
}

/* .site-footer {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 40vw;
} */
